<template>
<b-card no-body class="card-company-table">
    <b-row>
        <b-col>
            <h3 class="text-center">SINIFLAR</h3>
            <b-table sticky-header :items="classes" selectable select-mode="multi" ref="selectableTable" @row-selected="onClassRowSelected" responsive :fields="fields" class="mb-0">
                <!-- Id -->
                <template #cell(id)="data">
                    <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                </template>

                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <!-- Id -->
                <template #cell(username)="data">
                    <span class="font-weight-bolder mb-12">{{
                    (data.item!=null)?
                    data.item.firstName+' '+data.item.lastName:
                    "Kullanıcı Bulunamadı"
                    }}</span>
                </template>

                <template #cell(isAttendance)="data">
                    <b-form-checkbox :checked="data.item.isAttendance" v-model="data.item.isAttendance" @change="addUserAttendate(data.item)" class="custom-control-success" name="check-button" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </template>
            </b-table>
        </b-col>
        <b-col>
            <h3 class="text-center">DERSLER</h3>
            <b-table sticky-header :items="lessons" selectable select-mode="multi" ref="selectableTable" @row-selected="onRowSelected" responsive :fields="fields" class="mb-0">
                <!-- Id -->
                <template #cell(id)="data">
                    <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                </template>

                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <!-- Id -->
                <template #cell(username)="data">
                    <span class="font-weight-bolder mb-12">{{
                    (data.item!=null)?
                    data.item.firstName+' '+data.item.lastName:
                    "Kullanıcı Bulunamadı"
                    }}</span>
                </template>

                <template #cell(isAttendance)="data">
                    <b-form-checkbox :checked="data.item.isAttendance" v-model="data.item.isAttendance" @change="addUserAttendate(data.item)" class="custom-control-success" name="check-button" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </template>
            </b-table>
        </b-col>
    </b-row>
    <b-row>
        <b-button block variant="success" class="font-weight-bolder m-6" @click="addHookDetails">EKLE</b-button>
    </b-row>
</b-card>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'selected', label: 'Durum' },
                { key: 'name', label: 'Ad' },
            ],
            lessons: [],
            classes: [],
            selectedLesson: [],
            selectedClass: [],
        }
    },
    created() {
        this.getLesson();
        this.getClasses();
    },
    methods: {
        onRowSelected(items) {
            this.selectedLesson = items;
        },
        onClassRowSelected(items) {
            this.selectedClass = items;
        },
        async getLesson() {
            var lesson = await this.$http.get("Company/Lesson/" + this.$route.params.branchId);
            this.lessons = lesson.data.data;
        },
        async getClasses() {
            var classes = await this.$http.get("Company/Classes/" + this.$route.params.branchId);
            this.classes = classes.data.data;
        },
        async addHookDetails() {
            var request = [];
            this.selectedClass.forEach(cls => {
                this.selectedLesson.forEach(ls => {
                    request.push({
                        lessonId: ls.id,
                        classId: cls.id
                    });
                });
            });
            this.$http.post("Company/AddHookDetails/" + this.$route.params.uid, request).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Bildirim',
                        icon: 'BellIcon',
                        text: 'İşlem Başarılı.',
                        variant: 'success'
                    },
                });
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Bildirim',
                        icon: 'BellIcon',
                        text: error.response.data.error.message,
                        variant: 'danger'
                    },
                });
            });
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
